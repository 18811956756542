<script>
/**
 * Login component
 */
export default {
  data() {
    return {
      email: "",
      password: "",
      isAuthError: false,
      authError: "",
      loading: false
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;

      if (this.email && this.password) {
        this.$store.dispatch('auth/login', {
          email: this.email,
          password: this.password
        }).then(
            () => {
              this.$router.push('/benchmarks');
            },
            error => {
             this.showError("Sorry those credentials are invalid. Please try again");
             console.log(error);
            }
        );
      } else {
        this.showError("Please input credentials to continue");
      }
    },
    showError(message) {
      this.isAuthError = true;
      this.authError = message;
      this.loading = false;
    }
  }
};
</script>
<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <img src="/img/logo.png" alt class="logo-image"/>
    </div>
    <div class="col-md-6 col-lg-5 col-xl-3">
      <b-overlay :show="this.loading" rounded="sm">
        <div class="card overflow-hidden">
          <div class="bg-soft bg-white">
            <div class="row">
              <div class="col-12">
                <div class="p-4">
                  <h5 class="text-black">Welcome Back!</h5>
                  <p>Sign in to continue to the Product Benchmarking tool.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">

            <b-alert
                v-model="isAuthError"
                variant="danger"
                class="mt-3"
                dismissible
            >{{ authError }}
            </b-alert>

            <b-form class="p-2" @submit.prevent="handleLogin">
              <slot/>
              <b-form-group
                  id="input-group-1"
                  label="Email"
                  label-for="input-1"
                  class="mb-3"
              >
                <b-form-input
                    id="input-1"
                    name="email"
                    v-model="email"
                    type="text"
                    class="full-width"
                    placeholder="Enter email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Password"
                  label-for="input-2"
                  class="mb-3"
              >
                <b-form-input
                    id="input-2"
                    v-model="password"
                    name="password"
                    class="full-width"
                    type="password"
                    placeholder="Enter password"
                ></b-form-input>
              </b-form-group>
              <div class="mt-3 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                >Log In
                </b-button
                >
              </div>
              <div class="mt-4 text-center">
                <a href="/reset/password" class="text-muted">
                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                </a>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
      </b-overlay>
      <!-- end card -->
      <p class="text-center">
        © {{ new Date().getFullYear() }}. Latina SAM. All Rights Reserved.
      </p>
    </div>
    <!-- end row -->
  </div>
  <!-- end col -->
  <!-- end row -->
</template>

<style lang="scss">
form {
  label {
    text-align: left;
  }
}
</style>
